import React from 'react'

export default function ADashBoard() {
    return (
        <div>
             <iframe 
             width="1400px" 
             height="600px" 
             src="https://app.powerbi.com/view?r=eyJrIjoiNDA4ZDgxNGQtNTRkNi00YWJhLWJkZjAtZDViZDE0Yzg1ZjJlIiwidCI6ImRkODc3MmU1LTFiMGYtNDY4ZS05MmFlLTFhMTQzODYzYmJkNyIsImMiOjl9" 
             frameborder="0"
            allowFullScreen="true">
            </iframe>
        </div>
    )
}
