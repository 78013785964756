
import './App.css';
import { BrowserRouter,Switch,Route } from 'react-router-dom'
import SignIn from './component/Signin'
import SignUp from './component/Signup'
import Dashboard from './component/Dashboard'

function App() {
  return (
    <div>
<BrowserRouter>
      <Switch>
      <Route exact path="/" component={SignIn}></Route>
        <Route path="/signin" component={SignIn}></Route>
        <Route path="/Signup" component={SignUp}></Route>
        <Route path="/Dashboard" component={Dashboard}></Route>
      </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
