import React, { useState, useEffect } from 'react'
import { Card, CardContent, Typography, Grid } from '@material-ui/core'
import CountUp from 'react-countup'
//pie chart
// import { VictoryPie } from "victory-pie";
//Recharts Import
import PropTypes from 'prop-types';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { } from 'recharts';
import { PieChart, Pie, Sector, } from 'recharts';
import { RadialBarChart, RadialBar } from 'recharts';
import {
    ComposedChart,
    Line,
    Area,

} from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';




import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';


import axios from 'axios';
const colors = scaleOrdinal(schemeCategory10).range();
//pie chart
//end

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

//PieCharts Stuff START


//PieCharts Stuff END


// Vertical Bar Stuff Start
// const dataV = [
//     {
//         name: 'Page A',
//         uv: 590,
//         pv: 800,
//         amt: 1400,
//     },
//     {
//         name: 'Page B',
//         uv: 868,
//         pv: 967,
//         amt: 1506,
//     },
//     {
//         name: 'Page C',
//         uv: 1397,
//         pv: 1098,
//         amt: 989,
//     },
//     {
//         name: 'Page D',
//         uv: 1480,
//         pv: 1200,
//         amt: 1228,
//     },
//     {
//         name: 'Page E',
//         uv: 1520,
//         pv: 1108,
//         amt: 1100,
//     },
//     {
//         name: 'Page F',
//         uv: 1400,
//         pv: 680,
//         amt: 1700,
//     },
// ];
// Vertical Bar Stuff End

export default function DefactHandlingDashboard() {

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'black', 'yellow', 'brown'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
                {/* {countStatus[index].defectStatus} */}
            </text>
        );
    };

    //     const RADIAN = Math.PI / 180;

    // const renderCustomizedLabel = ({
    //   x, y, name
    // }) => {
    //   return (
    //     <text x={x} y={y} fill="black" textAnchor="end" dominantBaseline="central">
    //       {name}
    //     </text>
    //   );
    // };

    const radadata = [
        {
            subject: 'Math',
            A: 120,
            B: 110,
            fullMark: 150,
        },
        {
            subject: 'Chinese',
            A: 98,
            B: 130,
            fullMark: 150,
        },
        {
            subject: 'English',
            A: 86,
            B: 130,
            fullMark: 150,
        },
        {
            subject: 'Geography',
            A: 99,
            B: 100,
            fullMark: 150,
        },
        {
            subject: 'Physics',
            A: 85,
            B: 90,
            fullMark: 150,
        },
        {
            subject: 'History',
            A: 65,
            B: 85,
            fullMark: 150,
        },
    ];
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];



    const [defect, setDefect] = useState([])
    const [openDefacts, setOpenDefacts] = useState([])
    const [createdDefacts, setCreatedDefacts] = useState([])
    const [openDuration, setOpenDuration] = useState([])
    const [countStatus, setCountStatus] = useState([])
    const [departmentDefects, setDepartmentdefects] = useState([])
    const [machineDefects, setMachinedefects] = useState([])
    const [countAnamoly, setCountAnamoly] = useState([])

    const [openDu, setOpenDu] = useState([{
        anamoly: "",
        TimeDiff: ""
    }])
    const [loading, setLoading] = useState(true)

    const [stat, setStat] = useState({
        Close: 0,
        Open: 0,
        Fixed: 0,
        Complete: 0
    })

    const defectData = async () => {
        const fetchedData = await axios.get("https://come.pakistanautomation.com.pk/api/defects/selectDefect")
        setDefect(fetchedData.data.data)
        DCal(fetchedData.data.data)
        setLoading(false)
        console.log(stat)

    }
    const OpenDefect = async () => {
        const OpenD = await axios.get("https://come.pakistanautomation.com.pk/api/defects/openDefect")
        setOpenDefacts(OpenD.data)


    }
    const DepartmentDefects = async () => {
        const DeparD = await axios.get("https://come.pakistanautomation.com.pk/api/defects/CountDepartment")
        setDepartmentdefects(DeparD.data)


    }
    const MachineDefects = async () => {
        const MachD = await axios.get("https://come.pakistanautomation.com.pk/api/defects/CountMachine")
        setMachinedefects(MachD.data)


    }


    const CreatedDefect = async () => {
        const createdD = await axios.get("https://come.pakistanautomation.com.pk/api/defects/createdDefect")
        setCreatedDefacts(createdD.data)
    }

    const OpenDuration = async () => {
        const OpenDur = await axios.get("https://come.pakistanautomation.com.pk/api/defects/OpenDuration")
        console.log(OpenDur)
        // OpenDur.data.map(item => {
        //     console.log(item.TimeDiff)
        //     // var d = new Date(item.TimeDiff);
        //     // console.log(d)
        //     // var n = d.getHours();
        //     // console.log(n)
        //     var a = item.TimeDiff.split(':'); // split it at the colons

        //     // minutes are worth 60 seconds. Hours are worth 60 minutes.
        //     var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        //     var minutes = seconds / 60
        //     var hours = minutes / 60
        //     var days = hours/24
        //     console.log(hours)
        //     console.log(minutes)
        //     console.log(seconds);
        //     setOpenDu({
        //         anamoly: item.anamoly,
        //         TimeDiff: days
        //     })

        // })
        setOpenDuration(OpenDur.data)
    }

    const CountStat = async () => {
        const countstats = await axios.get("https://come.pakistanautomation.com.pk/api/defects/CountStatus")
        setCountStatus(countstats.data)
        console.log(countstats.data)

    }
    const CountAnamoly = async () => {
        const countAnamol = await axios.get("https://come.pakistanautomation.com.pk/api/defects/CountAnamoly")
        setCountAnamoly(countAnamol.data)
        console.log(countAnamol.data)
    }
   

    // console.log(openDefacts)
    // console.log(createdDefacts)
    // console.log(openDuration)
    // console.log(openDu)
    console.log(countAnamoly)
    // console.log(departmentDefects)
    // console.log(machineDefects)


    function DCal(dat) {
        console.log(dat)
        let Open = dat.filter(item => item.defectStatus > 'Open');
        console.log(Open);
        let close = dat.filter(item => item.defectStatus == 'Closed');
        console.log(close);
        let Fixed = dat.filter(item => item.defectStatus == 'Fixed');
        console.log(Fixed);
        let Complete = dat.filter(item => item.defectStatus == 'Complete');
        console.log(Complete);
        setStat({
            Close: close.length,
            Open: Open.length,
            Fixed: Fixed.length,
            Complete: Complete.length
        })

    }

    function DataSorted() {
        //Dept Sortion
        let dept =  departmentDefects.sort(function(a, b){
            let deptA = a.Total
            let deptB = b.Total
            if (deptA < deptB) 
            {
              return -1;
            }    
            else if (deptA > deptB)
            {
              return 1;
            }   
            return 0;
          });
       
       
        console.log(dept)

        //machine Sortion
        let mac =  machineDefects.sort(function(a, b){
            let macA = a.Total
            let macB = b.Total
            if (macA < macB) 
            {
              return -1;
            }    
            else if (macA > macB)
            {
              return 1;
            }   
            return 0;
          });
          console.log(mac)
        //Age of Call Data Sortion
        let od =  openDuration.sort(function(a, b){
            let aocA = a.DAYS
            let aocB = b.DAYS
            if (aocA < aocB) 
            {
              return -1;
            }    
            else if (aocA > aocB)
            {
              return 1;
            }   
            return 0;
          });
          console.log(od)
          //Open Defect Sortion
          
          let openDef =  openDefacts.sort(function(a, b){
            let openA = a.Total
            let openB = b.Total
            if (openA < openB) 
            {
              return -1;
            }    
            else if (openA > openB)
            {
              return 1;
            }   
            return 0;
          });
          console.log(openDef)
     
       //Total Anamoly Data Sortion
        let anamoly =  countAnamoly.sort(function(a, b){
            let dateA = a.Total
            let dateB = b.Total
            if (dateA < dateB) 
            {
              return -1;
            }    
            else if (dateA > dateB)
            {
              return 1;
            }   
            return 0;
          });
       
       
        console.log(anamoly)

        
    }
    DataSorted()

    useEffect(() => {
        defectData()
        OpenDefect()
        CreatedDefect()
        OpenDuration()
        CountStat()
        DepartmentDefects()
        MachineDefects()
        CountAnamoly()
        console.log(defect)
    }, [])



    return (
        <div>
            <div className="DHGCards">
                <div>
                    <Card style={{
                        color: "black",
                        backgroundImage: 'linear-gradient(to right, #56e8ab , #1ab0ab)',
                        backgroundColor: '#85FFBD',

                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body1" component="h4" align="center">
                                <span className="textSize">Total Defects</span>
                            </Typography>
                            <Typography variant="body1" align="center">

                                <CountUp start={0} end={defect.length} duration={3} separator={','}></CountUp>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card style={{
                        color: "black",
                        backgroundImage: 'linear-gradient(to right, #56e8ab , #1ab0ab)',
                        backgroundColor: '#85FFBD',
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body1" component="h4" align="center">
                                <span className="textSize">Open Defects</span>
                            </Typography>
                            <Typography variant="body1" align="center"><CountUp start={0} end={stat.Open} duration={3} separator={','}></CountUp>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card style={{
                        color: "black",
                        backgroundImage: 'linear-gradient(to right, #56e8ab , #1ab0ab)',
                        backgroundColor: '#85FFBD',
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body1" component="h4" align="center">
                                <span className="textSize">Close Defects</span>
                            </Typography>
                            <Typography variant="body1" align="center"><CountUp start={0} end={stat.Close} duration={3} separator={','}></CountUp>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card style={{
                        color: "black",
                        backgroundImage: 'linear-gradient(to right, #56e8ab , #1ab0ab)',
                        backgroundColor: '#85FFBD',
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body1" component="h4" align="center">
                                <span className="textSize">Fixed Defects</span>
                            </Typography>
                            <Typography variant="body1" align="center"><CountUp start={0} end={stat.Fixed} duration={3} separator={','}></CountUp>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card style={{
                        color: "black",
                        backgroundImage: 'linear-gradient(to right, #56e8ab , #1ab0ab)',
                        backgroundColor: '#85FFBD',
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body1" component="h4" align="center">
                                <span className="textSize">Complete Defects</span>
                            </Typography>
                            <Typography variant="body1" align="center"><CountUp start={0} end={stat.Complete} duration={3} separator={','}></CountUp>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
                    { machineDefects.length<0 || departmentDefects.length<0 || createdDefacts.length<0?
                    <div>Loading.....</div>
                    :
                    <div className="graphContainer">
                <div className="DHBar2">

                    <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Department Defects</h3>
                    <BarChart
                        width={250}
                        height={190}
                        data={departmentDefects}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="department" tick={{ fontSize: 10 }} />
                        <YAxis />
                        <Tooltip />
                       
                        <Bar dataKey="Total" fill="green" />


                    </BarChart>

                </div>
                <div className="DHBar2">

                    <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Machine Defects</h3>
                    <BarChart
                        width={250}
                        height={190}
                        data={machineDefects}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,


                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="machine" tick={{ fontSize: 10 }} />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend /> */}
                        <Bar dataKey="Total" fill="red" />

                    </BarChart>

                </div>

                <div className="DHBar2">
                    <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Defects Reported By</h3>
                    <BarChart
                        width={250}
                        height={190}
                        data={createdDefacts}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="createdBy" />
                        <YAxis />
                        <Bar dataKey="COUNT(createdBy)" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                            ))}
                        </Bar>
                    </BarChart>
                </div>
                <div className="DHVBar">
                    {/* <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>Age Of Call</h3>
                    <ComposedChart
                        layout="vertical"
                        width={250}
                        height={190}
                        data={openDuration}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis />
                        <YAxis dataKey="TimeDiff" type="category" scale="band" />
                        <Tooltip />
                        {/* <Legend /> */}
                    {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                    <Bar dataKey="anamoly" barSize={20} fill="#413ea0" />
                    {/* <Line dataKey="uv" stroke="#ff7300" /> */}
                    {/* </ComposedChart> */}
                    <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Age Of Call</h3>
                    <BarChart
                        width={250}
                        height={190}
                        data={openDuration}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,


                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="anamoly" tick={{ fontSize: 10 }} />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend /> */}
                        <Bar dataKey="DAYS" fill="purple" />

                    </BarChart>
                </div>
                <div className="DHBar">

                    <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Open Defects</h3>
                    <BarChart
                        width={250}
                        height={190}
                        data={openDefacts}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,


                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="anamoly" tick={{ fontSize: 10 }} />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend /> */}
                        <Bar dataKey="Total" fill="#a31034" />

                    </BarChart>

                </div>
                <div className="DHPie">
                    <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Total Defects Status</h3>
                    <RadarChart
                        cx={250}
                        cy={100}
                        outerRadius={70}
                        width={500}
                        height={200}
                        data={countStatus}
                    >
                        <PolarGrid />
                        <PolarAngleAxis dataKey="defectStatus" />
                        <PolarRadiusAxis />
                        <Radar
                            name="Mike"
                            dataKey="Total"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                        />
                    </RadarChart>
                    {/* <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>Total Defects Status</h3>
                    <Chart
                        width={'250px'}
                        height={'200px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[countStatus.map(d => Array.from(Object.values(d)))]}
                        options={{
                            title: 'My Daily Activities',
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    /> */}


                    {/* <PieChart width={200} height={200}>
                        <Pie
                            data={countStatus}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="COUNT(defectStatus)"

                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                // <Bar dataKey="defectStatus"></Bar>
                            ))}
                        </Pie>
                    </PieChart> */}

                    {/* </ResponsiveContainer> */}
                </div>
                <div className="DHBar">

                    <h3 style={{ textAlign: "center", fontWeight: "bolder", border: "1px solid black", borderRadius: "15px" }}>Total Anamolies Reported</h3>
                    <BarChart
                        width={850}
                        height={190}
                        data={countAnamoly}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,


                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="anamoly" tick={{ fontSize: 10 }} />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend /> */}
                        <Bar dataKey="Total" fill="#2E3B55" />

                    </BarChart>

                </div>

            </div>
                    }
            


        </div>
    )
}

