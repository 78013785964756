import React from 'react'
import {Link,useHistory} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
  root: {
    maxWidth: 280,
    backgroundColor:"#DFB7D8"
  },
  media: {
    height: 140,
  },
  cardmedia:{
    width:"120px",height:"120px",margin:"auto"
  },
});
export default function Cards() {
    const history = useHistory()
    const classes = useStyles();
    return (
        <div style={{height:"80vh",backgroundImage: "url('./images/bonaImage.png')",backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    }
        }>
            <div className="centerHeading">
                <h1 >DEFECT HANDLING SYSTEM</h1> </div>
            <div className="card-container">
                    <div>
                        <Link to="/Dashboard/Defacts">
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia 
                                 className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/administrator.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text" className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                      <span className="textSize">Report Defect</span>
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                        </Link>
                    </div>
                    <div>
                        <Link to="/Dashboard/DHGraph">
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia 
                                 className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    width="200"
                                    image="/icon/MES1.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                    <span className="textSize">Defect Dashboard</span>
                                     
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                        </Link>
                    </div>
                    <div>
                        <Link to="/Dashboard/AnalysisDashboard">
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia 
                                 className={classes.cardmedia}
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/KPI.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                    <span className="textSize">Analytics</span>
                                      
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                        </Link>
                    </div>
                    {/* <div>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/Quality-.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center" >
                                    <span className="textSize">QUALITY</span>
                        
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </div>
                    <div>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/E&T.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                    <span className="textSize">E & T</span>
                                        
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </div> 
                    <div>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/supply-chain.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                    <span className="textSize">SUPPLY CHAIN</span>
                                        
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </div>
                    <div>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/Initiative_management.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                    <span className="textSize">INITIATIVE MANAGEMENT</span>
                                        
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </div>
                    <div>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/Safety.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                    <span className="textSize">HEALTH & SAFETY</span>
                                        
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </div>
                    <div>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/Org___Change_Management.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                    <span className="textSize">ORGANIZATION & CHANGE</span>
                                       
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </div>
                    <div>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="PAKAUTO"
                                    height="140"
                                    image="/icon/Root_Cause_analysis.png"
                                    title="PakAuto Logo"
                                />
                                <CardContent className="card-text">
                                    <Typography gutterBottom variant="h4" component="h4" align="center">
                                    <span className="textSize">ADVANCE ANALYTICS</span>
                                        
                            </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </div> */}
                </div>
        </div>
    )
}
