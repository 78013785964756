




import React, { useState, useEffect } from 'react';
import axios from 'axios'
import jwt from "jsonwebtoken";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import DropdownTreeSelect from "react-dropdown-tree-select";
// import dataTree from '../../../data.json'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';


import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';



//Select imports from material ui 
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//Material Date Imports
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';




// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';










    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 90,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    // },





function getStripedStyle(index) {
    return { background: index % 2 ? '#fafafa' : 'white' };
}



const useStyles = makeStyles((theme) => ({

    modal: {
        height: '100%',
        width: '100%'

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    sizeInc: {
        width: '120%'
    },
    buttonSubmit: {
        //    marginTop:'150px'
    },
    paper: {
        height: "auto",
        // width: '90%',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: "linear-gradient(to bottom, #abbaab, #ffffff)",
        border: '2px solid #000',
        borderRadius: "1rem",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position: "absolute",
        top: "50px",
        left: "30%",
        marginTop: "-50px",
        marginLeft: "-50px",
        // width: "100px",
        // height: "100px"

    },
    // form: {
    //     width: '100%', // Fix IE 11 issue.
    //     overflowY:"scroll",
    //     display:"flex",

    //     // marginTop: theme.spacing(1),
    //     flexWrap: 'wrap',
    //     justifyContent:"center",
    //     // flex:3
    //     // display: 'inline-flex',
    //     // flexDirection: 'row',
    //     // justifyContent: 'space-evenly',
    //     // gap: '17%',
    //     // flexGrow: '0.1',
    //     // marginTop: '3em',





    // },

    submit: {
        margin: theme.spacing(3, 0, 2),
        marginTop: '3',
        float: 'right'
    },
    root: {
        '& .super-app-theme--header': {
            backgroundColor: 'black',
            color: 'white',
        },
    },
}));

// Testing & Experiment Ground Start
export const ExData = {
    Plant: ["Alaves", "Atletico", "Barcelona"],
    Department: ["Atalanta", "Juventus", "Inter"]
};

export const leagues = Object.keys(ExData);

const leagueOptions = leagues.map(name => <option key={name}>{name}</option>);
const getTeams = league =>
    league && league !== ""
        ? ExData[league].map(name => (
            <option key={name} value={name}>
                {name}
            </option>
        ))
        : [];
// Testing & Experiment Ground End

// Tree Select Area
const onChangeTree = (currentNode, selectedNodes) => {
    console.log("path::", currentNode.path);
    // console.log(selectedNodes[0].label)
};

const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach(k => {
        const node = obj[k];
        if (typeof node === "object") {
            node.path = stack ? `${stack}.${k}` : k;
            assignObjectPaths(node, node.path);
        }
    });
};
// Tree Select Area

export default function DefactsHandling() {
    const clearForm = () => {
        setSelectedDepartment("");
        setLine("");
        setSelectedMachine("");
        setHfound("");
        setWfound("");
        setPriority("");
        setAnamoly("");
        setDefactStat("");
        setRespon("");
        setActionPlan("");
        setAmDetail("");
        setTD(new Date());
        setCD(new Date())
    }
    const columns = [
        {
            field: 'id', title: 'ID', cellStyle: {
                backgroundColor: '#039be5',
                color: '#FFF'
            },
            headerStyle: {
                backgroundColor: '#039be5',
            }
        },
        { field: 'department', title: 'Department' },
        { field: 'line', title: 'Line' },
        { field: 'hfound', title: 'How Found?' },
        { field: 'wfound', title: 'Who Found?' },
        { field: 'priority', title: 'Priority' },
        { field: 'machine', title: 'Machine' },
        { field: 'anamoly', title: 'Anamoly' },
        { field: 'defectStatus', title: 'Defect Status' },
        { field: 'responsible', title: 'Responsible' },
        { field: 'actionPlan', title: 'Action Plan' },
        { field: 'targetDate', title: 'Target Date' },
        { field: 'closeDate', title: 'Close Date' },
        { field: 'createdOn', title: 'Created On' },
        { field: 'createdBy', title: 'Created By' },
    ];
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [openR, setOpenR] = React.useState(false);
    const handleOpenR = () => {
        setOpenR(true);
    };

    const handleCloseR = () => {
        setOpenR(false);
    };



    const [exd, setExd] = useState({
        selectedLeague: leagues[0],
        selectedTeam: getTeams(leagues[0])[0]
    })

    const handleLeagueChange = (e) => {
        setExd({ selectedLeague: e.target.value });
    };

    const handleTeamChange = (e) => {
        setExd({ selectedTeam: e.target.value });
    };

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const [SelectAnamoly, setAnamoly] = useState('');
    const [DefactStat, setDefactStat] = useState('');
    const [AmDetail, setAmDetail] = useState('')
    const [Respon, setRespon] = useState('')
    const [ActionPlan, setActionPlan] = useState('')
    const [TD, setTD] = React.useState(new Date());
    const [CD, setCD] = useState(new Date())
    const [tableDATA, setTableDATA] = useState([])
    const [loading, setLoading] = useState(true)
    const [Departmentdata, setDepartmentData] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState("")
    const [Machinedata, setMachineData] = useState([])
    const [selectedMachine, setSelectedMachine] = useState("")
    const [line, setLine] = useState("")
    const [hfound, setHfound] = useState("")
    const [wfound, setWfound] = useState("")
    const [priority, setPriority] = useState("")
    const [userRole, setUserRole] = useState(0);
    const handleChange = (event) => {
        setAnamoly(event.target.value);
    };
    const handleChangeStatus = (event) => {
        setDefactStat(event.target.value);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    function testingData() {
        console.log(tableDATA.data)
    }
    testingData()

    //Fetching Data for Table
    const defectData = async () => {
        const fetchedData = await axios.get("https://come.pakistanautomation.com.pk/api/defects/selectDefect")
        setTableDATA(fetchedData.data)
        setLoading(false)
    }
    //Fetching Data For Departements
    const getdepartments = async () => {
        const fetchedData = await axios.get("https://come.pakistanautomation.com.pk/api/departments/getDepartment")
        console.log(fetchedData.data)
        setDepartmentData(fetchedData.data.department)

    }
    //Fetching Data For Machines
    const getmachines = async () => {
        const fetchedData = await axios.get("https://come.pakistanautomation.com.pk/api/machines/getMachines")
        console.log(fetchedData.data)
        setMachineData(fetchedData.data.machines)

    }
    // let testData = [
    //     {
    //         key: 1,
    //         value: 'plantA'
    //     },
    //     {
    //         key: 1,
    //         value: 'plantB'
    //     },
    //     {
    //         key: 1,
    //         value: 'plantC'
    //     },
    //     {
    //         key: 1,
    //         value: 'plantD'
    //     }
    // ]

    // let optionItems = testData.map((planet) =>
    //     <MenuItem key={planet.key}>{planet.value}</MenuItem>
    // );


    useEffect(() => {
        defectData()
        getdepartments()
        getmachines()
        var token = localStorage.getItem("token");
        console.log("token:"+token)
        var decoded = jwt.decode(token, { complete: true });
        console.log(decoded)
        const isAdmin = decoded.payload.isAdmin;
        console.log(isAdmin)
        setUserRole(isAdmin);
    }, [])

    console.log(userRole)
    //Form Submit Handler
    const submitHandler = (e) => {
        e.preventDefault();
        console.log({
            department: selectedDepartment,
            machine: selectedMachine,
            line: line,
            hfound: hfound,
            wfound: wfound,
            priority: priority,
            anamoly: SelectAnamoly,
            defectStatus: DefactStat,
            anamolyDetail: AmDetail,
            responsible: Respon,
            actionPlan: ActionPlan,
            targetDate: TD
        })
        axios.post("https://come.pakistanautomation.com.pk/api/defects/createDefect", {
            department: selectedDepartment,
            machine: selectedMachine,
            line: line,
            hfound: hfound,
            wfound: wfound,
            priority: priority,
            anamoly: SelectAnamoly,
            defectStatus: DefactStat,
            anamolyDetail: AmDetail,
            responsible: Respon,
            actionPlan: ActionPlan,
            targetDate: TD,
            closeDate: CD,
            createdOn: new Date(),
            createdBy: localStorage.getItem('username')
        }).then(res => {
            console.log(res)
            if (res.status === 201) {
                setOpenR(false)
                defectData()
                clearForm()
                // console.log(tableDATA)
                console.log(res.data.message)
                console.log("ddddddddddddd"+res.status)

            }
            else {
                setOpenR(true)
                console.log(res.data.message)
            }
        })

    }
    return (
        <div className="DHContainer">
            <div>
                <Button

                    type="button"
                    onClick={handleOpenR}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddCircleOutlineIcon />}
                >
                    Add Report
      </Button>
                <Dialog
                    fullScreen={fullScreen}
                    open={openR}
                    onClose={handleCloseR}
                    aria-labelledby="responsive-dialog-title"
                    PaperProps={{
                        style: {
                            background: "linear-gradient(to bottom, silver, #ffffff)",

                        },
                    }}
                >
                    <DialogTitle id="responsive-dialog-title"><img src="/icon/alert1.png" alt="alertimage" width="60px" height="50px"></img>
                        <Typography component="h1" variant="h5" style={{ color: "black", fontWeight: "bolder", fontFamily: 'ariel' }}>
                            ADD DEFECT
                            </Typography></DialogTitle>

                    <DialogContent>

                        <form className="dhForm" noValidate onSubmit={submitHandler}>
                            <div className="div1t6">
                                <div>
                                    <FormControl required
                                        className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Departements</InputLabel>
                                        <Select
                                            className={classes.sizeInc}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            onChange={e => setSelectedDepartment(e.target.value)}
                                            value={selectedDepartment}
                                        >
                                            {Departmentdata.map(dep => (
                                                <MenuItem value={dep.department}>{dep.department}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl required
                                        className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Line</InputLabel>
                                        <Select
                                            className={classes.sizeInc}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            onChange={e => setLine(e.target.value)}
                                            value={line}
                                        >
                                            <MenuItem value={'A'}>A</MenuItem>
                                            <MenuItem value={'B'}>B</MenuItem>
                                            <MenuItem value={'C'}>C</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl required
                                        className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Machines</InputLabel>
                                        <Select
                                            className={classes.sizeInc}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            onChange={e => setSelectedMachine(e.target.value)}
                                            value={selectedMachine}
                                        >
                                            {Machinedata.map(mach => (
                                                <MenuItem value={mach.name}>{mach.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl required
                                        className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">How Found?</InputLabel>
                                        <Select
                                            className={classes.sizeInc}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={hfound}
                                            onChange={e => setHfound(e.target.value)}

                                        >
                                            <MenuItem value={'CIL'}>CIL</MenuItem>
                                            <MenuItem value={'Breakdown'}>Breakdown</MenuItem>
                                            <MenuItem value={'PM'}>PM</MenuItem>
                                            <MenuItem value={'Production'}>Production</MenuItem>
                                            <MenuItem value={'GEMBA'}>GEMBA</MenuItem>
                                            <MenuItem value={'Safety'}>Safety</MenuItem>
                                            <MenuItem value={'Quality'}> Quality</MenuItem>
                                            <MenuItem value={'Projects'}>Projects</MenuItem>
                                            <MenuItem value={'Changeover'}>Changeover</MenuItem>
                                            <MenuItem value={'RLS'}>RLS</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <TextField id="ResB" value={wfound} onChange={(e) => setWfound(e.target.value)} label="Who Found?" variant="outlined" required
                                    />
                                </div>
                                <div>
                                    <FormControl required
                                        className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                                        <Select
                                            className={classes.sizeInc}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={priority}
                                            onChange={e => setPriority(e.target.value)}

                                        >
                                            <MenuItem value={'High'}>High</MenuItem>
                                            <MenuItem value={'Medium'}>Medium</MenuItem>
                                            <MenuItem value={'Low'}>Low</MenuItem>


                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl required
                                        className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Anamoly Type</InputLabel>
                                        <Select
                                            className={classes.sizeInc}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={SelectAnamoly}
                                            onChange={handleChange}

                                        >
                                            <MenuItem value={'Lack of Cleaning'}>Lack of Cleaning</MenuItem>
                                            <MenuItem value={'High Tempreature'}>High Tempreature</MenuItem>
                                            <MenuItem value={'Low Tempreature'}>Low Tempreature</MenuItem>
                                            <MenuItem value={'Low Pressure'}>Low Pressure</MenuItem>
                                            <MenuItem value={'Collision'}>Collision</MenuItem>
                                            <MenuItem value={'Missing / Broken Parts'}>Missing / Broken Parts</MenuItem>
                                            <MenuItem value={'Damage'}>Damage</MenuItem>
                                            <MenuItem value={'Damage Electrical Parts'}>Damage Electrical Parts</MenuItem>
                                            <MenuItem value={'Damage Mechanical Parts'}>Damage Mechanical Parts</MenuItem>
                                            <MenuItem value={'Vibration'}>Vibration</MenuItem>
                                            <MenuItem value={'Rumps'}>Rumps</MenuItem>
                                            <MenuItem value={'Damage Sensor'}>Damage Sensor</MenuItem>
                                            <MenuItem value={'Damage VFD'}>Damage VFD</MenuItem>
                                            <MenuItem value={'Burn'}>Burn</MenuItem>
                                            <MenuItem value={'IN Sufficient Lubrication'}>IN Sufficient Lubrication</MenuItem>
                                            <MenuItem value={'HTRA Hard to Reach Area'}>HTRA Hard to Reach Area</MenuItem>
                                            <MenuItem value={'Noise'}>Noise</MenuItem>
                                            <MenuItem value={'Lack of instructions (Opl, SOP)'}>Lack of instructions (Opl, SOP)</MenuItem>
                                            <MenuItem value={'Lack of Basic Condition'}>Lack of Basic Condition</MenuItem>
                                            <MenuItem value={'Worned out'}>Worned out</MenuItem>
                                            <MenuItem value={'Crack'}>Crack</MenuItem>
                                            <MenuItem value={'Chemical Damage'}>Chemical Damage</MenuItem>
                                            <MenuItem value={'Corrision'}>Corrision</MenuItem>
                                            <MenuItem value={'Rusted'}>Rusted</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="div7t12">
                                <div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required

                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="SELECT TARGET DATE"
                                            value={TD}
                                            onChange={setTD}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required

                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="SELECT CLOSE DATE"
                                            value={CD}
                                            onChange={setCD}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div>
                                    <FormControl required
                                        className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Defact Status</InputLabel>
                                        <Select
                                            className={classes.sizeInc}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={DefactStat}
                                            onChange={handleChangeStatus}
                                        >
                                            <MenuItem value={'Open '}>Open </MenuItem>
                                            <MenuItem value={'Closed'}>Closed</MenuItem>
                                            <MenuItem value={'Complete'}>Complete</MenuItem>
                                            <MenuItem value={'Eradicate'}>Eradicate</MenuItem>
                                            <MenuItem value={'Fixed'}>Fixed</MenuItem>
                                            <MenuItem value={'Repaired'}>Repaired</MenuItem>
                                            <MenuItem value={'Temporary Solved'}>Temporary Solved</MenuItem>
                                            <MenuItem value={'Solved'}>Solved</MenuItem>
                                            <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <TextField id="ResB" value={Respon} onChange={(e) => setRespon(e.target.value)} label="Responsible" variant="outlined" required
                                    />
                                </div>
                                <div>
                                    <TextField id="AP" value={ActionPlan} onChange={(e) => setActionPlan(e.target.value)} label="Action Plan" variant="outlined" required
                                    />
                                </div>
                                <div>
                                    <TextField id="AD" value={AmDetail} onChange={(e) => setAmDetail(e.target.value)} label="Anamoly Detail" variant="outlined" required style={{ marginTop: '1.2em' }} />
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"

                                    className={classes.submit}
                                    endIcon={<SendIcon />}
                                >
                                    Save & Close
                             </Button>
                            </div>
                        </form>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button autoFocus onClick={handleCloseR} color="primary">
                            Disagree
          </Button>
                        <Button onClick={handleCloseR} color="primary" autoFocus>
                            Agree
          </Button>
                    </DialogActions> */}
                </Dialog>
            </div>
            <div>
                {userRole ? (
          loading ? <div className="skeletonAdjust">
             <SkeletonTheme color="#A1CDE4" highlightColor="#039BE5">
            <Skeleton 
            count={8}
            duration={1 }
            />
            </SkeletonTheme>
          
          </div>
                    :
                    <MaterialTable

                        style={{ marginTop: "10px", whiteSpace: 'nowrap', maxWidth: '90%', overflowX: 'scroll', overflowY: 'scroll', position: 'absolute' }}
                        title="Defects Report"
                        columns={columns}
                        data={
                            tableDATA.data
                        }
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataUpdate = [...tableDATA.data];
                                        const index = oldData.id;

                                        // console.log(index)
                                        dataUpdate[index] = newData;
                                        // console.log(newData)
                                        // console.log(newData.machine)
                                        axios.post("https://come.pakistanautomation.com.pk/api/defects/updateDefect",newData).then(res=>{
                                            if(res.data.results==="Success"){
                                                console.log("updatedSuccessfully")
                                                defectData()
                                            }
                                            else{
                                                console.log(res.data.results)
                                                console.log(newData)
                                            }
                                        })
                                        resolve();
                                    }, 1000)
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataDelete = [...tableDATA.data];
                                        const index = oldData.id;
                                        // console.log(index)
                                        // console.log(oldData);
                                        // dataDelete.splice(index, 1);
                                        // setTableDATA([...dataDelete]);
                                        // console.log(index)
                                        axios.post("https://come.pakistanautomation.com.pk/api/defects/deleteDefect",{id:index}).then(res=>{
                                            if(res.data.results==="Success"){
                                                console.log("deletedSuccessfully")
                                                defectData()
                                            }
                                            else{
                                                console.log(res.data.results)
                                            }
                                        })

                                        resolve()
                                        
                                    }, 1000)
                                }),

                        }}
                        // actions={[
                        //     {

                        //         icon: 'save',
                        //         tooltip: 'Save User',

                        //         icon: () => <EditIcon style={{ color: 'black' }} />,
                        //         tooltip: "Modification in process...",
                        //         //   onClick: (event, rowData) => handleOpenForm(rowData)
                        //     },
                        // ]}
                        options={{
                            filtering: true,
                            exportButton: true,
                            // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

                            headerStyle: {
                                backgroundColor: '#2E3B55',
                                color: 'white'
                            }
                        }}
                        // actions={[
                        //     {

                        //         icon: 'save',
                        //         tooltip: 'Save User',

                        //         icon: () => <EditIcon style={{ color: 'black' }} />,
                        //         tooltip: "Modification in process...",
                        //         //   onClick: (event, rowData) => handleOpenForm(rowData)
                        //     },
                        // ]}
                        options={{
                            filtering: true,
                            exportButton: true,
                            // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],

                            headerStyle: {
                                backgroundColor: '#2E3B55',
                                color: 'white'
                            }
                        }}
                    />
                  )  : (
                    loading ? <div className="skeletonAdjust">
                    <SkeletonTheme color="#A1CDE4" highlightColor="#039BE5">
                   <Skeleton 
                   count={8}
                   duration={1 }
                   />
                   </SkeletonTheme>
                 
                 </div> :
                      
                        <MaterialTable
                          actions={[
                            {
                              disabled: true,
                              icon: "edit",
                              tooltip: "Edit Team",
                              onClick: (event, rowData) => {
                                // Do save operation
                              },
                            },
                            {
                              disabled: true,
                              icon: "delete",
                              tooltip: "Delete Team",
                              onClick: (event, rowData) => {
                                // Do save operation
                              },
                            },
                          ]}
                          style={{
                            marginTop: "10px",
                            whiteSpace: "nowrap",
                            maxWidth: "250%",
                            tableLayout: "fixed",
                          }}
                          title="Team"
                          columns={columns}
                          data={tableDATA.data}
                          //  editable={{
                          //    onRowUpdate: (newData, oldData) =>
                          //      new Promise((resolve, reject) => {
                          //        setTimeout(() => {
                          //          const dataUpdate = [...causeData];
                          //          const index = oldData.id;
              
                          //          // console.log(index)
                          //          dataUpdate[index] = newData;
                          //          console.log(newData);
                          //          setCauseData([...dataUpdate]);
                          //          resolve();
                          //        }, 1000);
                          //      }),
                          //    onRowDelete: (oldData) =>
                          //      new Promise((resolve, reject) => {
                          //        setTimeout(() => {
                          //          const dataDelete = [...causeData];
                          //          const index = oldData.id;
                          //          // console.log(index)
                          //          // console.log(oldData);
                          //          dataDelete.splice(index, 1);
                          //          setCauseData([...dataDelete]);
              
                          //          resolve();
                          //        }, 1000);
                          //      }),
                          //  }}
                          // actions={[
                          //     {
              
                          //         icon: 'save',
                          //         tooltip: 'Save User',
              
                          //         icon: () => <EditIcon style={{ color: 'black' }} />,
                          //         tooltip: "Modification in process...",
                          //         //   onClick: (event, rowData) => handleOpenForm(rowData)
                          //     },
                          // ]}
                          options={{
                            filtering: true,
                            exportButton: true,
                            // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],
              
                            headerStyle: {
                              backgroundColor: "#2E3B55",
                              color: "white",
                            },
                          }}
                        />
                        )}
                        </div>

            {/* <button onClick={testingData}>somme</button> */}
        </div>
    );
}
