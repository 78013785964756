
import React,{useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import Link from '@material-ui/core/Link';
import {Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import Paper from '@material-ui/core/paper';
import LockIcon from '@material-ui/icons/Lock'; 
import Axios from'axios';
import jwt from 'jsonwebtoken'


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



//const paperStyle={padding:`30px 20px`,height:`85vh`,width:350,margin:"20 auto",alignItems:"center", background:'linear-gradient(#02070a,#22ced3)', opacity:0.75}

function Copyright() {
  return (
    <div>   
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link  href="httpss://www.facebook.com/PakistanAutomation">
        <span style={{color:'black'}}>PAKISTAN AUTOMATION </span> 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    marginTop:-15,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const history = useHistory();
  // const buttonHandler=()=>{
  //   history.push('/Dashboard')
  // }
  const classes = useStyles();
const url='https://come.pakistanautomation.com.pk/api/users/login'
// 'http://localhost:3000/api/device/getTime'
const [data, setData] = useState({
  email:"",
  password:"",
})
function submit(e){
  e.preventDefault();
  Axios.post(url,{
email: data.email,
password: data.password,
  })
  .then(res=>{
    console.log(res.data)
    localStorage.setItem('token',res.data.token)
    if(localStorage.getItem('token')){
      history.push('/Dashboard')
    }
    else{
      console.log(res.data.message)
    }
  //  decoder(res.data.token)
  })
}
// function decoder(token){
//   var decoded = jwt.decode(token, {complete: true});
// console.log(decoded.header);
// console.log(decoded.payload);
// // localStorage.setItem('username',decoded.payload.username)
// localStorage.setItem('token',token)
// if(token){
//   history.push('/Dashboard')
// }
// else{
//   console.log('lol just sign up first')
// }
// }
const paperStyle={padding :20,height:'80vh',width:280, margin:"20px auto" ,background:'linear-gradient(#02070a,#22ced3)', opacity:0.75}
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const btnstyle={margin:'8px 0'}
function handle(e){
const newdata={...data}
newdata[e.target.id]= e.target.value
setData(newdata)
console.log(newdata)
}
  return (
   // <ResponsiveGridLayout>
     // breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
       // cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>:}}>
       <div className="bg" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/images/sm.jpg'})`,boxSizing:'inherit',width:'100%'}}>
<Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                     <Avatar style={avatarStyle}><LockOutlinedIcon style={{color:'white'}}/></Avatar>
                    <h2 style={{color:'white'}}>Sign In</h2>
                </Grid>
                <form className={classes.form} noValidate onSubmit={(e)=>submit(e)}>
        <PersonIcon style={{color:'white'}}/>
          <TextField onChange={(e)=>handle(e)}id="email" value={data.email} 
          InputProps={{className:"textfield_insidetext"}}
          InputLabelProps={{className:"textfield_label"}} 
          variant="filled"
            // variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <LockIcon style={{color:'white'}}/>
          <TextField onChange={(e)=>handle(e)}id="password" value={data.password}
          InputProps={{className:"textfield_insidetext"}}
          InputLabelProps={{className:"textfield_label"}} 
          variant="filled"
            // variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
          
            autoComplete="current-password"
          />
         
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            // onClick={buttonHandler}
          >
            Sign In
          </Button>
       </form>
       <Grid item style={{color:'white'}}>
              Don't have an account?
              <Link href="/signup" variant="body2" style={{color:'black'}}>
                {"Sign Up"}
              </Link>
            </Grid>
            <Box mt={5}>
        <Copyright />
      </Box>
               {/* <PersonIcon style={{color:'white'}}/>
                <TextField label='Email' placeholder='Enter email' fullWidth required/>
                <br/>
                <LockIcon style={{color:'white'}}/>
                <TextField label='Password' placeholder='Enter password' type='password' fullWidth required/>
              
                <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Sign in</Button>
                <Typography >
                     <Link href="#" >
                        Forgot password ?
                </Link>
                </Typography>
                <Typography > Do you have an account ?
                     <Link href="#" >
                        Sign Up 
                </Link>
  </Typography>*/}
            </Paper>
        </Grid>
{/*<Grid style={{padding:`30px 20px`, margin:`20px auto`}}>

    <Container component="main" maxWidth="xs" className="contact-wrap"spacing={2} >
    
    <Paper elevation={10} style={paperStyle}>
      <CssBaseline />
      <div className={classes.paper}>
     
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" style={{color:'white'}}>
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={(e)=>submit(e)}>
        <PersonIcon style={{color:'white'}}/>
          <TextField onChange={(e)=>handle(e)}id="email" value={data.email} 
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <LockIcon style={{color:'white'}}/>
          <TextField onChange={(e)=>handle(e)}id="password" value={data.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
          
            autoComplete="current-password"
          />
         
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            // onClick={buttonHandler}
          >
            Sign In
          </Button>
          <Grid container>
           
            <Grid item style={{color:'white'}}>
              Don't have an account?
              <Link href="/signup" variant="body2" style={{color:'black'}}>
                {"Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
        
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      </Paper>
      
    </Container>

  </Grid>*/}

    </div>
   //  </ResponsiveGridLayout>
  );
}