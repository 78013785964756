// import React,{useState} from 'react';
// import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/paper';
// import axios from 'axios';
// import { useHistory } from 'react-router-dom';

// const paperStyle1={padding:`30px 20px`,height:`87vh`,width:400,margin:"20 auto",alignItems:"center"}



// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://www.facebook.com/PakistanAutomation">
//       <span style={{color:'black'}}>PAKISTAN AUTOMATION </span> 
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//     marginTop:-15,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(3),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

// export default function SignUp() {
//   const history = useHistory()
//   const classes = useStyles();
// const url='http://localhost:3000/api/users/createUser'
//   const [data,setdata] = useState({
// firstName:"",
// lastName:"",
// email:"",
// password:"",
//   })
//   function submit(e){
// e.preventDefault();
// axios.post(url,{
// email:data.email,
// username:data.firstName +' '+ data.lastName,
// password:data.password,

// })
// .then(res=>{
//   console.log(res.data)
//   if(res.data.message==="User registered successfully")
//   {
//     history.push('/signin')
//   }
//   else{
//     console.log(res.data.message)
//   }
// })

//   }
// function handle(e){
//   const newData={...data}
//   newData[e.target.id]=e.target.value
//   setdata(newData)
//   console.log(newData)
// }
//   return (
//     <div className="bg" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/images/webbg.jpg'})`}}>
// <Grid style={{padding:`30px 20px`, margin:`20px auto`}}>
//     <Container component="main" maxWidth="xs">
//     <Paper elevation={10} style={paperStyle1}>
//       <CssBaseline />
//       <div className={classes.paper}>
//         <Avatar className={classes.avatar}>
//           <LockOutlinedIcon />
//         </Avatar>
//         <Typography component="h1" variant="h5">
//           Sign up
//         </Typography>
//         <form className={classes.form} noValidate onSubmit={(e)=>submit(e)}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={6}>
//               <TextField onChange={(e)=>handle(e)} id="firstName" value={data.firstName}
//                 autoComplete="fname"
//                 name="firstName"
//                 variant="outlined"
//                 required
//                 fullWidth
              
//                 label="First Name"
//                 autoFocus
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField onChange={(e)=>handle(e)} id="lastName" value={data.lastName}
//                 variant="outlined"
//                 required
//                 fullWidth
                
//                 label="Last Name"
//                 name="lastName"
//                 autoComplete="lname"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField onChange={(e)=>handle(e)} id="email" value={data.email}
//                 variant="outlined"
//                 required
//                 fullWidth
              
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField onChange={(e)=>handle(e)} id="password" value={data.password}
//                 variant="outlined"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
              
//                 autoComplete="current-password"
//               />
//             </Grid>
//           </Grid>
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             color="primary"
//             className={classes.submit}
//           >
//             Sign Up
//           </Button>
//           <Grid container justify="flex-end">
//             <Grid item>Already have an account?
//               <Link href="/signin" variant="body2">
//                  Sign in
//               </Link>
//             </Grid>
//           </Grid>
//         </form>
//       </div>
//       <Box mt={5}>
//         <Copyright />
//       </Box>
//       </Paper>
//     </Container>
//     </Grid>
//     </div>
//   );
// }


// import React,{useState} from 'react';
// import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/paper';
// import axios from 'axios';
// import { useHistory } from 'react-router-dom';

// const paperStyle1={padding:`30px 20px`,height:`87vh`,width:400,margin:"20 auto",alignItems:"center", background:'linear-gradient(#02070a,#22ced3)', opacity:0.75}



// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://www.facebook.com/PakistanAutomation">
//       <span style={{color:'black'}}>PAKISTAN AUTOMATION </span> 
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//     marginTop:-15,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(3),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

// export default function SignUp() {
//   const history = useHistory()
//   const classes = useStyles();
// const url='http://localhost:3000/api/users/createUser'
//   const [data,setdata] = useState({
// firstName:"",
// lastName:"",
// email:"",
// password:"",
//   })
//   function submit(e){
// e.preventDefault();
// axios.post(url,{
// email:data.email,
// username:data.firstName +' '+ data.lastName,
// password:data.password,

// })
// .then(res=>{
//   console.log(res.data)
//   if(res.data.message==="User registered successfully")
//   {
//     history.push('/signin')
//   }
//   else{
//     console.log(res.data.message)
//   }
// })

//   }
// function handle(e){
//   const newData={...data}
//   newData[e.target.id]=e.target.value
//   setdata(newData)
//   console.log(newData)
// }
//   return (
//     <div className="bg" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/images/sm.jpg'})`}}>
// <Grid style={{padding:`30px 20px`, margin:`20px auto`}}>
//     <Container component="main" maxWidth="xs">
//     <Paper elevation={10} style={paperStyle1}>
//       <CssBaseline />
//       <div className={classes.paper}>
//         <Avatar className={classes.avatar}>
//           <LockOutlinedIcon />
//         </Avatar>
//         <Typography component="h1" variant="h5" style={{color:'white'}}>
//           Sign up
//         </Typography>
//         <form className={classes.form} noValidate onSubmit={(e)=>submit(e)}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={6}>
//               <TextField onChange={(e)=>handle(e)} id="firstName" value={data.firstName}
//                 autoComplete="fname"
//                 name="firstName"
//                 variant="outlined"
//                 required
//                 fullWidth
              
//                 label="First Name"
//                 autoFocus
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField onChange={(e)=>handle(e)} id="lastName" value={data.lastName}
//                 variant="outlined"
//                 required
//                 fullWidth
                
//                 label="Last Name"
//                 name="lastName"
//                 autoComplete="lname"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField onChange={(e)=>handle(e)} id="email" value={data.email}
//                 variant="outlined"
//                 required
//                 fullWidth
              
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField onChange={(e)=>handle(e)} id="password" value={data.password}
//                 variant="outlined"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
              
//                 autoComplete="current-password"
//               />
//             </Grid>
//           </Grid>
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             color="primary"
//             className={classes.submit}
//           >
//             Sign Up
//           </Button>
//           <Grid container justify="flex-end">
//             <Grid item style={{color:'white'}}>Already have an account?
//               <Link href="/signin" variant="body2" style={{color:'black'}}>
//                  Sign in
//               </Link>
//             </Grid>
//           </Grid>
//         </form>
//       </div>
//       <Box mt={5}>
//         <Copyright />
//       </Box>
//       </Paper>
//     </Container>
//     </Grid>
//     </div>
//   );
// }

import React,{useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/paper';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
//mehak editing

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

//const paperStyle1={padding:`30px 20px`,height:`87vh`,width:400,margin:"20 auto",alignItems:"center", background:'linear-gradient(#02070a,#22ced3)', opacity:0.75}



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.facebook.com/PakistanAutomation">
      <span style={{color:'black'}}>PAKISTAN AUTOMATION </span> 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    marginTop:-15,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//mehak editing
const paperStyle = { padding: 20, width: 300, margin: "20px auto",background:'linear-gradient(#02070a,#22ced3)', opacity:0.75 }
    const headerStyle = { margin: 0 }
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    const marginTop = { marginTop: 5 }

export default function SignUp() {
  const history = useHistory()
  const classes = useStyles();
const url='https://come.pakistanautomation.com.pk/api/users/createUser'
  const [data,setdata] = useState({
firstName:"",
lastName:"",
email:"",
password:"",
  })
  function submit(e){
e.preventDefault();
axios.post(url,{
email:data.email,
username:data.firstName +' '+ data.lastName,
password:data.password,

})
.then(res=>{
  console.log(res.data)
  if(res.data.message==="User registered successfully")
  {
    history.push('/signin')
  }
  else{
    console.log(res.data.message)
  }
})

  }
function handle(e){
  const newData={...data}
  newData[e.target.id]=e.target.value
  setdata(newData)
  console.log(newData)
}
  return (
    <div className="bg" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/images/sm.jpg'})`,boxSizing:'inherit',width:'100%'}}>
<Grid>
            <Paper style={paperStyle}>
                <Grid align='center'>
                    <Avatar style={avatarStyle}>
                        <AddCircleOutlineOutlinedIcon />
                    </Avatar>
                    <h2 style={headerStyle} style={{color:'white'}}>Sign Up</h2>
                    <Typography variant='caption' style={{color:'white'}} gutterBottom>Please fill this form to create an account !</Typography>
                </Grid>
               {/* <form>
                    <TextField fullWidth label='Name' placeholder="Enter your name" />
                    <TextField fullWidth label='Email' placeholder="Enter your email" />
                    <FormControl component="fieldset" style={marginTop}>
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup aria-label="gender" name="gender" style={{ display: 'initial' }}>
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                        </RadioGroup>
                    </FormControl>
                    <TextField fullWidth label='Phone Number' placeholder="Enter your phone number" />
                    <TextField fullWidth label='Password' placeholder="Enter your password"/>
                    <TextField fullWidth label='Confirm Password' placeholder="Confirm your password"/>
                    <FormControlLabel
                        control={<Checkbox name="checkedA" />}
                        label="I accept the terms and conditions."
                    />
                    <Button type='submit' variant='contained' color='primary'>Sign up</Button>
               </form>*/}
               <form className={classes.form} noValidate onSubmit={(e)=>submit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField onChange={(e)=>handle(e)} id="firstName" value={data.firstName}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
              
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField onChange={(e)=>handle(e)} id="lastName" value={data.lastName}
                variant="outlined"
                required
                fullWidth
                
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField onChange={(e)=>handle(e)} id="email" value={data.email}
                variant="outlined"
                required
                fullWidth
              
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField onChange={(e)=>handle(e)} id="password" value={data.password}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
              
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item style={{color:'white'}}>Already have an account?
              <Link href="/signin" variant="body2" style={{color:'black'}}>
                 Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
        <Box mt={5}>
        <Copyright />
      </Box>
            </Paper>
        </Grid>
{/*<Grid style={{padding:`30px 20px`, margin:`20px auto`}}>
    <Container component="main" maxWidth="xs" style={{boxSizing:'inherit',width:'100%',padding:'20px'}}>
    <Paper elevation={10} style={paperStyle1}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" style={{color:'white'}}>
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={(e)=>submit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField onChange={(e)=>handle(e)} id="firstName" value={data.firstName}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
              
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField onChange={(e)=>handle(e)} id="lastName" value={data.lastName}
                variant="outlined"
                required
                fullWidth
                
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField onChange={(e)=>handle(e)} id="email" value={data.email}
                variant="outlined"
                required
                fullWidth
              
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField onChange={(e)=>handle(e)} id="password" value={data.password}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
              
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item style={{color:'white'}}>Already have an account?
              <Link href="/signin" variant="body2" style={{color:'black'}}>
                 Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      </Paper>
    </Container>
  </Grid> */}
    </div>
  );
}