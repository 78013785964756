import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
//icons
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { Switch, Route, Link, useHistory, useRouteMatch } from 'react-router-dom'
import DefactsHandling from './DefectHandling'
import DefectHandlingDashboard from './DefectHandlingDashboard'
import Cards from './Cards'

// import SignIn from '../SignIn';
import jwt from 'jsonwebtoken'
import ADashBoard from './AnalyticsDashboard';
const drawerWidth = 380;

const useStyles = makeStyles((theme) => ({
    // rootTest: {
    //     flexGrow: 1,
    // },
    menuButtonTest: {
        marginRight: theme.spacing(2),
    },
    titleTest: {
        flexGrow: 1,
    },
    root: {
        display: 'flex',
        height: 'fit-content'
    },
    appBar: {
        background: '#B41F8C',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        background: '#F700F7',
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth ,
        flexShrink: 1,
        whiteSpace: 'nowrap',


    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        background: '#F0F2F5'
    },
    test: {
        flexGrow: 1,
    }
}));

export default function MiniDrawer(props) {
    const [userRole, setUserRole] = useState(0);
    const match = useRouteMatch()
    const history = useHistory();
    console.log(props)
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [listOpen1, setListOpen1] = React.useState(false)
    const [listOpen2, setListOpen2] = React.useState(false)
    const [listOpen3, setListOpen3] = React.useState(false)
    const [listOpen4, setListOpen4] = React.useState(false)
    const [username,setUserName] = React.useState('')
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleListClick1 = () => {
        setListOpen1(!listOpen1);
    };
    const handleListClick2 = () => {
        setListOpen2(!listOpen2);
    };
    const handleListClick3 = () => {
        setListOpen3(!listOpen3);
    };

    const goBack=()=>{
        history.goBack()
    }
    const goForward=()=>{
        history.goForward()
    }

    const handleListClick4 = () => {
        setListOpen4(!listOpen4);
    };
    function decoder(){
        var token = localStorage.getItem('token')
  var decoded = jwt.decode(token, {complete: true});

  if(decoded== null){
      history.push('/signin')
  }
  else{
   
    console.log(decoded.payload)
  localStorage.setItem('username',decoded.payload.username)
  var user =localStorage.getItem('username')
  return user
  }
  

}

    const logoutHandle = () => {
        localStorage.removeItem('username')
        localStorage.removeItem('token')
        if(localStorage.getItem('username')){
            console.log('here')
        }
        else{
            history.push('/signin')
        }
    }

        useEffect(() => {
            var token = localStorage.getItem("token");
            var decoded = jwt.decode(token, { complete: true });
            const isAdmin = decoded.payload.isAdmin;
            setUserRole(isAdmin);
            decoder()
          
        }, [])
    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.rootTest}>
                <AppBar position="fixed" className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.titleTest}>
                        <Button onClick={goBack}>
                               <ArrowBackIos  style={{fill: "black"}}/>
                           </Button>
                           <Button onClick={goForward}>
                               <ArrowForwardIos  style={{fill: "black"}}/>
                           </Button>
                            <Link to='/Dashboard' className="logoText">BONA PAPA</Link>
                        </Typography>
                        <Typography>{ 
                        decoder()
                        }</Typography>
                        <Button onClick={logoutHandle} color="inherit"><span className="logoText1">  Logout </span></Button>
                    </Toolbar>
                </AppBar>
            </div>
            <div className="drawer">
                <Drawer
                // temporary
                variant="permanent"
                open={open}
                // open={open}
                onEscapeKeyDown={handleDrawerClose}
                onBackdropClick={handleDrawerClose }
                        //  variant="temporary" open={open} onEscapeKeyDown={handleDrawerClose} onBackdropClick={handleDrawerClose}
                    // style={{backgroundColor:'black'}}
                   
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,

                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <List>
                        {/* {["CARDS"].map((text, index) => (
                            <ListItem style={{ textDecoration: 'none', color: 'black' }} key={text} component={Link} to={"/" + text}>
                                <ListItemIcon>
                                    {index === 0 && <DashboardIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>

                        ))} */}
                        {/* <ListItem button >
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <Link to={match.url + "/CARDS"} style={{ textDecoration: 'none', color: 'black' }} ><ListItemText primary="CARDS" /></Link>
                        </ListItem> */}
                        {/* First list  */}
                        {/* <ListItem button onClick={handleListClick1}>
                            <ListItemIcon>
                                {/* <InboxIcon /> */}
                                {/* <img src='/icon/Shop_floor3.png' width="25px" height="23px"></img>
                            </ListItemIcon>
                            <ListItemText primary="SHOP FLOOR" />
                            {listOpen1 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem> */} 
                        {/* <Collapse in={listOpen1} timeout="auto" unmountOnExit> */}
                            <List component="div" disablePadding>
                                {/* first list items */}
                                {/* <Link to={match.url + "/OEE"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                           <img src='/icon/OEE1.png' width="25px" height="23px"></img>
                                        </ListItemIcon>
                                        <ListItemText secondary="OEE" />
                                    </ListItem>
                                </Link>

                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                           <img src='/icon/Downtime_loss.png' width="25px" height="23px"></img>
                                        </ListItemIcon>
                                        <ListItemText secondary="Losses/Downtime" />
                                    </ListItem>
                                </Link> */}

                                <Link to={match.url + "/DHGraph"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                           <img src='/icon/Waste1.png' width="25px" height="23px"></img>
                                        </ListItemIcon>
                                        <ListItemText secondary="Defect Dashboard" />
                                    </ListItem>
                                </Link>

                                

                                {/* <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                           <img src='/icon/waste.png' width="25px" height="23px"></img>
                                        </ListItemIcon>

                                        <ListItemText secondary="Waste" />
                                    </ListItem>
                                </Link>

                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                           <img src='/icon/Production.png' width="25px" height="23px"></img>
                                        </ListItemIcon>
                                        <ListItemText secondary="Produce" />
                                    </ListItem>
                                </Link>

                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                           <img src='/icon/Performance.png' width="25px" height="23px"></img>
                                        </ListItemIcon>
                                        <ListItemText secondary="Performance" />
                                    </ListItem>
                                </Link> */}

                            </List>
                        {/* </Collapse> */}
                        {/* Second list  */}
                        {/* <ListItem button onClick={handleListClick2}>
                            <ListItemIcon>
                              
                                <img src='/icon/MES1.png' width="25px" height="23px"></img>
                            </ListItemIcon>
                            <ListItemText primary="MANUFACTURING EXCELLENCE" />
                            {listOpen2 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={listOpen2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="OCM" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Pillars" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="KPI" />
                                    </ListItem>
                                </Link>

                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Routes/Deployment" />
                                    </ListItem>
                                </Link>

                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Auditing" />
                                    </ListItem>
                                </Link>

                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="SIS" />
                                    </ListItem>
                                </Link>

                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="PCS" />
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse> */}
                        {/* third list  */}
                        {/* <ListItem button onClick={handleListClick3}>
                            <ListItemIcon>
                            <img src='/icon/administrator.png' width="25px" height="23px"></img>
                            </ListItemIcon>
                            <ListItemText primary="ADMINISTRATION" />
                            {listOpen3 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem> */}
                        {/* <Collapse in={listOpen3} timeout="auto" unmountOnExit> */}
                            <List component="div" disablePadding>
                                {/* <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Page Configuration" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Machine Configuration" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/machinetype"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Machine Type" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/machine"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Machine" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/department"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Department" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/team"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Team" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/shift"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Shift" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/sku"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="SKU" />
                                    </ListItem>
                                </Link> */}
                                <Link to={match.url + "/Defacts"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                    <ListItemIcon>
                                           <img src='/icon/administrator.png' width="25px" height="23px"></img>
                                        </ListItemIcon>
                                        <ListItemText secondary="Defacts Handling" />
                                    </ListItem>
                                </Link>
                                {/* <Link to={match.url + "/Orders"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Order Handling" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/stops"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Machine Stops" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/cause1"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Cause1" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/cause2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Cause2" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/cause3"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Cause3" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="Product Configuration" />
                                    </ListItem>
                                </Link>
                                <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <StarBorder />
                                        </ListItemIcon>
                                        <ListItemText secondary="User Management" />
                                    </ListItem>
                                </Link> */}

                            </List>
                        {/* </Collapse> */}
                        {/* <ListItem button onClick={handleListClick4}>
                            <ListItemIcon>
                            <img src='/icon/Analytics2-.png' width="25px" height="23px"></img>
                            </ListItemIcon>
                            <ListItemText primary="ANALYTICS" />
                            {listOpen4 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem> */}
                        {/* <Collapse in={listOpen4} timeout="auto" unmountOnExit> */}
                            <List component="div" disablePadding>
                            <Link to={match.url + "/AnalysisDashboard"} style={{ textDecoration: 'none', color: 'black' }} >
                                <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                           <img src='/icon/KPI.png' width="25px" height="23px"></img>
                                        </ListItemIcon>
                                    <ListItemText secondary="Dashboard" />
                                </ListItem>
                                </Link>
                                {/* <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} >
                                <ListItem button className={classes.nested}>
                                    <ListItemIcon>
                                        <StarBorder />
                                    </ListItemIcon>
                                    <Link to={match.url + "/PAGE2"} style={{ textDecoration: 'none', color: 'black' }} ><ListItemText secondary="Plant KPI's" /></Link>
                                </ListItem>
                                </Link> */}
                            </List>
                        {/* </Collapse> */}
                        {userRole? 
                   <Link
                   to={match.url + "/UserManage"}
                   style={{ textDecoration: "none", color: "black" }}
                 >
                   <ListItem button className={classes.nested}>
                     <ListItemIcon>
                       <img
                         src="/icon/management.png"
                         width="25px"
                         height="23px"
                       ></img>
                     </ListItemIcon>
                     <ListItemText secondary="User Management" />
                   </ListItem>
                 </Link>
                :
                null
                }
                    </List>
                </Drawer>
            </div>
            <main className={classes.content}>

                <div className={classes.toolbar} />

                {/* defining All routes here */}
                <Switch>
                    <Route exact path="/DASHBOARD" >
                        <Cards />
                    </Route>
                    <Route path={match.url + "/CARDS"} component={Cards} />

                    <Route path={match.url + '/Defacts'} component={DefactsHandling} />
                    <Route path={match.url + '/DHGraph'} component={DefectHandlingDashboard} />
                  
                    <Route path={match.url + '/AnalysisDashboard'} component={ADashBoard} />
           
                    

                </Switch>
            </main>

        </div>
    );
}